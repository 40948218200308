@font-face {
    font-family: "HelveticaNeueBlack";
    src: url("./HelveticaNeueBlack.otf");
}

@font-face {
    font-family: "HelveticaNeueLight";
    src: url("./HelveticaNeueLight.otf");
}

h1{
    font-family: HelveticaNeueBlack;
}

div{
    font-family: HelveticaNeueLight; 
}

span{
    font-family: Arial, Helvetica, sans-serif; 
}